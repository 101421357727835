import React from "react";

import "./TeamMember.css";

const linkedInlogo = "assets/symbols/LinkedIn.svg";
const instaLogo = "assets/symbols/Instagram.svg";
const mail = "assets/symbols/mail.fill.svg";

const TeamMember = ({
  fullName,
  image,
  title,
  subTitle,
  background,
  responsibilties,
  LinkedInURL,
  InstagramURL,
  email,
}) => {
  return (
    <>
      <div className="teamMember">
        <div class="square-box">
          <div class="square-content">
            <img className="profileImage" src={image}></img>
          </div>
        </div>

        <div
          className="textDescription"
          style={{
            whiteSpace: "pre-line",
            textAlign: "left",
          }}
        >
          <div className="name">{fullName}</div>
          <div
            style={{
              fontWeight: "800",
              color: "#00c7c7",
            }}
          >
            {title}
          </div>
          <div style={{ fontWeight: "bolder" }}>Background</div>
          <div style={{}}>{background}</div>
          <div style={{ fontWeight: "bolder" }}>Responsibilities</div>
          <div>{responsibilties}</div>
          <div style={{ fontWeight: "bolder" }}>Connect</div>
          <div className="socialGrid">
            {email ? (
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="socialLogo" src={mail}></img>
              </a>
            ) : null}
            {LinkedInURL ? (
              <a href={LinkedInURL}>
                <img className="socialLogo" src={linkedInlogo}></img>
              </a>
            ) : null}
            {InstagramURL ? (
              <a href={InstagramURL}>
                <img className="socialLogo" src={instaLogo}></img>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMember;
