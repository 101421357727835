import React from "react";
import { InstagramEmbed } from "react-social-media-embed";

const SocialsSection = () => {
  return (
    <>
      <div className="teamMembers">
        <InstagramEmbed
          url="https://www.instagram.com/p/Ct2zZ9hgIpq/"
          width={328}
          captioned={false}
        />
        <InstagramEmbed
          url="https://www.instagram.com/p/CjqVXOmOZ-p/"
          width={328}
        />
      </div>
    </>
  );
};

export default SocialsSection;
