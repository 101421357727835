import React from "react";
import YouTubeVideo from "./YouTubeVideo";

import "./VideoSection.css";

const VideoSection = () => {
  return (
    <>
      <div class="six-box">
        <div class="six-content">
          <iframe
            src="https://www.youtube.com/embed/FjtE64vDJm8?vq=hd1080&modestbranding=1&rel=0&iv_load_policy=3&color=white"
            width="100%"
            height="100%"
            title="Rowcave Introduction"
            frameborder="0"
            allowfullscreen="true"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default VideoSection;
