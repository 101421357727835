import React from "react";

import "./Header.css";

const logo = "assets/RowcaveLogo.svg";
const epicLogo = "assets/EpicLogo.svg";

const Header = () => {
  return (
    <div className="headerContainer">
      <img src={logo} className="logo" alt="logo" />
      <div className="header">MegaGrant Application</div>

      <img src={epicLogo} className="logo" alt="logo" />
      {/* <div className="megaGrid"></div> */}
    </div>
  );
};

export default Header;
