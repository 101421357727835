import React from "react";

import "./Section.css";

const textWave = "assets/textWave.svg";

const Section = ({ title, children }) => {
  return (
    <div className="section">
      <div className="sectionContainer">
        <div className="textBox">
          {title}
          <div className="textStyleContainer">
            {/* {" "}
            <img className="textStyling" src={textWave}></img> */}
          </div>
        </div>
        <div className="line" />

        <div className="sectionChildren"> {children}</div>
      </div>
    </div>
  );
};

export default Section;
