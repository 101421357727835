import React, { useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import PDFSection from "./components/PDFSection";
import Section from "./components/Reusable/Section";
import VideoSection from "./components/VideoSection";
import TeamSection from "./components/TeamSection";
import ContactSection from "./components/ContactSection";
import SocialsSection from "./components/SocialsSection";

function App() {
  useEffect(() => {
    fetch("https://us-central1-rowcave-megagrant.cloudfunctions.net/visitor")
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log("Error:", error));
  }, []);

  return (
    <div className="App">
      <div className="background"></div>
      <div className="backgroundAnimation">
        <div class="ocean">
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
      </div>
      <div className="scrollContainer">
        <div className="contentMain">
          <div className="Sections">
            <Header />
            <Section title={"introduction"}>
              <VideoSection />
            </Section>
            <Section title={"application"}>
              <PDFSection />
            </Section>
            <Section title={"team"}>
              <TeamSection />
            </Section>
            <Section title={"socials"}>
              <SocialsSection />
            </Section>
            <Section title={"contact"}>
              <ContactSection></ContactSection>
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
