import React from "react";
import "./PDFSection.css"; // Import your CSS file

const pdfImage = "assets/MegaGrant_1.jpg";

const PDFSection = () => {
  return (
    <div className="pdf-container">
      <div className="downloadContainer">
        <div className="download">Download</div>
      </div>
      <a
        href="assets/Rowcave MegaGrant Application.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="pdfImage"
          src={pdfImage}
          alt="View the Rowcave Prospectus"
        />
      </a>
    </div>
  );
};

export default PDFSection;
